@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
/* poppins-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  src: url("../Fonts/poppins-v21-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/poppins-v21-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../Fonts/poppins-v21-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../Fonts/poppins-v21-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* poppins-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../Fonts/poppins-v21-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
body {
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 30px;
  line-height: 1.2em;
  font-weight: 700;
  @apply text-dark-green mb-10px lg:mb-20px relative;
}
@media (min-width: 1024px) {
  h1 {
    font-size: 52px;
  }
}

h2 {
  font-size: 25px;
  line-height: 1.2em;
  font-weight: 500;
  @apply text-dark-green mb-20px lg:mb-30px relative;
}
@media (min-width: 1024px) {
  h2 {
    font-size: 45px;
  }
}

h3 {
  font-size: 22px;
  line-height: 1.2em;
  position: relative;
  font-weight: 500;
  margin-bottom: 18px;
}
@media (min-width: 1024px) {
  h3 {
    margin-bottom: 30px;
    font-size: 30px;
  }
}

main ul {
  margin: 15px 0;
  padding-left: 20px;
}
main ul li {
  list-style-type: disc;
  font-weight: 300;
  margin-bottom: 15px;
}

p {
  font-weight: 300;
}

p + p {
  margin-top: 20px;
}

p + h3 {
  margin-top: 50px;
}

header + .ce-textpic,
header + .news {
  margin-top: 15px;
}
@media (min-width: 1024px) {
  header + .ce-textpic,
  header + .news {
    margin-top: 40px;
  }
}

.frame-type-header h1 {
  margin-bottom: 0;
}

.section-bg-dark {
  @apply bg-dark-green py-80px;
}
.section-bg-dark header h1, .section-bg-dark header h2, .section-bg-dark header h3, .section-bg-dark header h4, .section-bg-dark header h5, .section-bg-dark header h6 {
  color: white;
}
.section-bg-dark a {
  @apply text-white hover:opacity-80 underline;
}

.section-bg-light {
  @apply bg-light-green py-80px;
}
.section-bg-light + .section-bg-light {
  @apply pb-80px pt-0 -mt-60px;
}

.section-bg-white {
  @apply bg-white py-40px;
}
.section-bg-white header h1, .section-bg-white header h2, .section-bg-white header h3, .section-bg-white header h4, .section-bg-white header h5, .section-bg-white header h6 {
  @apply text-dark-green;
}

main {
  width: 100%;
  padding-bottom: 100px;
  min-height: calc(100vh - 610px);
  padding-left: 0;
}
@media (min-width: 1024px) {
  main {
    padding-left: 20%;
  }
}
main a {
  @apply text-dark-green hover:opacity-80 underline;
}
main:after {
  content: "";
  display: block;
  clear: both;
}

footer {
  padding-left: 0;
  @apply bg-gray-600 text-white py-40px;
}
@media (min-width: 1024px) {
  footer {
    padding-left: 20%;
  }
}
footer a {
  @apply text-white hover:opacity-80 underline;
}
footer h3 {
  margin-bottom: 10px;
}
footer img {
  margin-bottom: 10px;
}

.header-image {
  height: 60vh;
}
.header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ce-center .ce-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  @apply mx-auto px-20px lg:px-0;
}

.sidebar {
  padding: 40px 40px 30px 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-x: hidden;
  z-index: 1001;
  border: none;
  scrollbar-width: none;
  background: #106963;
  background-image: -webkit-gradient(linear, right bottom, left top, from(#106963), to(#a3bab0));
  background-image: linear-gradient(to bottom, #106963, #a3bab0);
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
}
@media (min-width: 1024px) {
  .sidebar {
    width: 20%;
    position: fixed;
    transform: translateX(0);
  }
}
.sidebar.open {
  transform: translateX(0);
}
.sidebar .logo .logo-container {
  padding: 4px;
}
.sidebar .logo .logo-container img {
  background-color: white;
  border: solid 4px white;
  max-width: 125px;
}

.mainnav li a {
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  text-transform: uppercase;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
}
.mainnav li a.active {
  position: relative;
  margin-left: 30px;
}
.mainnav li a.active:before {
  content: "";
  position: absolute;
  display: inline-flex;
  top: 3px;
  left: -30px;
  width: 0;
  height: 0;
  transform: rotate(360deg);
  border-style: solid;
  border-width: 7px 0 7px 12.1px;
  border-color: transparent transparent transparent white;
}
.mainnav li a:hover {
  opacity: 0.8;
}
.mainnav .subnav {
  margin: 5px 0 30px 30px;
}
.mainnav .subnav li a {
  color: white;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-transform: none;
  margin-bottom: 5px;
}
.mainnav .subnav li a.active {
  position: relative;
  margin-left: 0;
}
.mainnav .subnav li a.active:before {
  content: "";
  position: absolute;
  display: inline-flex;
  top: 3px;
  left: -30px;
  width: 0;
  height: 0;
  transform: rotate(360deg);
  border-style: solid;
  border-width: 7px 0 7px 12.1px;
  border-color: transparent transparent transparent white;
}
.mainnav .subnav li a:hover {
  transform: translateX(-5px);
}

header {
  z-index: 1050;
  position: relative;
}
header .hamburger {
  width: 40px;
  height: 30px;
  position: relative;
}
header .hamburger .bar {
  padding: 0;
  width: 40px;
  height: 4px;
  display: block;
  transition: all 0.4s ease-in-out;
  position: absolute;
  @apply bg-dark-green;
}
header .hamburger .bar.bar1 {
  top: 0;
  transform-origin: 5%;
}
header .hamburger .bar.bar2, header .hamburger .bar.bar3 {
  top: 13px;
}
header .hamburger .bar.bar3 {
  right: 0;
}
header .hamburger .bar.bar4 {
  bottom: 0;
  transform-origin: 5%;
}
header .hamburger.open > .bar1 {
  transform: rotate(45deg);
  height: 4px;
  width: 42px;
}
header .hamburger.open > .bar3 {
  transform: rotate(45deg);
  height: 4px;
  background-color: transparent;
}
header .hamburger.open > .bar2 {
  transform: rotate(-45deg);
  height: 4px;
  background-color: transparent;
}
header .hamburger.open > .bar4 {
  transform: rotate(-45deg);
  height: 4px;
  width: 42px;
}

.news-list-view .article {
  margin-bottom: 150px;
}
.news-list-view .article img {
  transition: all 0.3s ease-in-out;
}
.news-list-view .article .news-preview-item {
  aspect-ratio: 40/55;
  display: block;
  background: #106963;
  background-image: -webkit-gradient(linear, right bottom, left top, from(#106963), to(#a3bab0));
  background-image: linear-gradient(to bottom, #106963, #a3bab0);
}
.news-list-view .article .header {
  position: absolute;
  bottom: -100px;
  transition: all 0.3s ease-in-out;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.news-list-view .article .header h3 {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 1.5em;
}
.news-list-view .article .header .teaser-text p {
  font-size: 16px;
  line-height: 1.5em;
  margin-bottom: 0;
  font-weight: 300;
}
.news-list-view .article:hover img {
  transform: scale(1.05);
}
.news-list-view .article:hover .header {
  transform: translateY(-50px);
}

.f3-widget-paginator li {
  list-style: none;
  margin: 0 4px;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  margin: 0 5px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}